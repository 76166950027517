import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";

const SearchBox = ({ currentRefinement, refine }) => (
  <div className="relative pl-5 md:pl-10 bg-[#F1FAFF] rounded-full flex items-center justify-between">
    <input
      type="text"
      value={currentRefinement}
      onChange={(event) => refine(event.currentTarget.value)}
      placeholder="Search Here"
      className="text-blue-600 blockH7 search-field w-3/4 focus:outline-none bg-[#F1FAFF] border-0 focus:shadow-none form-input focus:ring-0 "
    />
    <div className="bg-[#034EA215] py-3 md:py-6 rounded-full w-14 md:w-20 h-14 md:h-20 flex items-center justify-center">
      <svg
        className="hidden md:block"
        width="32"
        height="32"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.6845 17.1986L14.0222 12.5257C15.0813 11.1196 15.6354 9.39622 15.5946 7.63495C15.5539 5.87368 14.9207 4.17786 13.7977 2.82255C12.6747 1.46723 11.1278 0.531915 9.4079 0.1683C7.68801 -0.195315 5.896 0.0341003 4.32252 0.819335C2.74904 1.60457 1.48638 2.89957 0.739344 4.49428C-0.00769437 6.089 -0.195296 7.8899 0.206968 9.60489C0.609232 11.3199 1.57777 12.8484 2.95548 13.9424C4.33319 15.0365 6.03928 15.632 7.79701 15.6323C9.5012 15.6401 11.1597 15.0799 12.5114 14.0398L17.1998 18.6867C17.2982 18.7872 17.4158 18.8668 17.5456 18.9207C17.6754 18.9745 17.8147 19.0015 17.9552 18.9999C18.238 18.9978 18.5089 18.8855 18.7105 18.6867C18.9009 18.4843 19.0047 18.2154 18.9998 17.9373C18.995 17.6593 18.8818 17.3942 18.6845 17.1986V17.1986ZM7.82306 13.5177C6.88554 13.5165 5.96277 13.2836 5.13641 12.8398C4.31005 12.396 3.60556 11.7549 3.08529 10.9732C2.56502 10.1915 2.245 9.29336 2.15354 8.35818C2.06209 7.423 2.20203 6.47965 2.56097 5.61158C2.91991 4.74352 3.48679 3.97752 4.21145 3.38134C4.93611 2.78516 5.79621 2.37719 6.71565 2.19354C7.63509 2.00988 8.58552 2.05619 9.48285 2.32838C10.3802 2.60056 11.1967 3.09023 11.8603 3.75405C12.396 4.28102 12.8198 4.91114 13.1063 5.60653C13.3928 6.30192 13.536 7.04819 13.5273 7.80048C13.5264 9.31697 12.9272 10.7716 11.8603 11.8469C11.333 12.3818 10.704 12.8053 10.0106 13.0922C9.3171 13.3792 8.57327 13.5239 7.82306 13.5177V13.5177Z"
          fill="#034EA2"
        />
      </svg>
      <svg
        className="md:hidden"
        width="20"
        height="20"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.6845 17.1986L14.0222 12.5257C15.0813 11.1196 15.6354 9.39622 15.5946 7.63495C15.5539 5.87368 14.9207 4.17786 13.7977 2.82255C12.6747 1.46723 11.1278 0.531915 9.4079 0.1683C7.68801 -0.195315 5.896 0.0341003 4.32252 0.819335C2.74904 1.60457 1.48638 2.89957 0.739344 4.49428C-0.00769437 6.089 -0.195296 7.8899 0.206968 9.60489C0.609232 11.3199 1.57777 12.8484 2.95548 13.9424C4.33319 15.0365 6.03928 15.632 7.79701 15.6323C9.5012 15.6401 11.1597 15.0799 12.5114 14.0398L17.1998 18.6867C17.2982 18.7872 17.4158 18.8668 17.5456 18.9207C17.6754 18.9745 17.8147 19.0015 17.9552 18.9999C18.238 18.9978 18.5089 18.8855 18.7105 18.6867C18.9009 18.4843 19.0047 18.2154 18.9998 17.9373C18.995 17.6593 18.8818 17.3942 18.6845 17.1986V17.1986ZM7.82306 13.5177C6.88554 13.5165 5.96277 13.2836 5.13641 12.8398C4.31005 12.396 3.60556 11.7549 3.08529 10.9732C2.56502 10.1915 2.245 9.29336 2.15354 8.35818C2.06209 7.423 2.20203 6.47965 2.56097 5.61158C2.91991 4.74352 3.48679 3.97752 4.21145 3.38134C4.93611 2.78516 5.79621 2.37719 6.71565 2.19354C7.63509 2.00988 8.58552 2.05619 9.48285 2.32838C10.3802 2.60056 11.1967 3.09023 11.8603 3.75405C12.396 4.28102 12.8198 4.91114 13.1063 5.60653C13.3928 6.30192 13.536 7.04819 13.5273 7.80048C13.5264 9.31697 12.9272 10.7716 11.8603 11.8469C11.333 12.3818 10.704 12.8053 10.0106 13.0922C9.3171 13.3792 8.57327 13.5239 7.82306 13.5177V13.5177Z"
          fill="#034EA2"
        />
      </svg>
    </div>
  </div>
);

export const CustomSearchBox = connectSearchBox(SearchBox);
