import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { connectHits } from "react-instantsearch-dom";

const Hit = ({ slug, title, seo }) => {
  return (
    <div className="pb-10 border-b border-[#9A9A9A] flex flex-col gap-y-3">
      <span className="blockH3">{title}</span>
      <p className="blockH7">{seo?.metaDesc}</p>
      <Link to={`/${slug.current}`} className="linkH2 text-blue-600">
        READ ARTICLE
      </Link>
    </div>
  );
};

const Hits = ({ hits }) => {
  return (
    <div className="mt-16 md:mt-32 flex flex-col gap-y-10">
      {hits.map((hit) => (
        <Hit {...hit} />
      ))}
    </div>
  );
};

export const BlogHits = connectHits(Hits);
