import React from "react";
import {
  InstantSearch,
  connectStateResults,
  Configure,
} from "react-instantsearch-dom";

import Layout from "@components/layout";
import { CustomSearchBox } from "@components/search/customSearchBox";
import { BlogHits } from "@components/search/blogHits";
import { searchClient } from "@lib/algolia";
import { PageMeta } from "@components/pageMeta";

// const IndexResults = connectStateResults(({ searchState, searchResults, children }) =>
//     searchState.query && searchResults && searchResults.nbHits !== 0 ? children : <></>
// );

const Search = () => {
  return (
    <Layout>
      <PageMeta metaTitle="Search" />
      <div className="py-36 md:py-48 px-gutter md:mx-gutter min-h-screen">
        <InstantSearch searchClient={searchClient} indexName="blogs">
          <Configure hitsPerPage={600} />
          <CustomSearchBox placeholder="Search" />
          {/* <IndexResults> */}
          <BlogHits />
          {/* </IndexResults> */}
        </InstantSearch>
      </div>
    </Layout>
  );
};

export default Search;
